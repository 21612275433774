import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Seo from "../components/seo"
import Header from "../components/header"
import Layout from "../components/layout"
import { CaseCard } from "../components/caseCard"
import { Footer } from "../components/footer"

const CasePage = () => {
  const {
    cases,
  } = useStaticQuery(
    graphql`
        query {
            cases: allMarkdownRemark(sort: {order: ASC, fields: frontmatter___title}) {
                edges {
                    node {
                        frontmatter {
                            title
                            titleShort
                            subTitle
                            image {
                                extension
                                publicURL
                                childImageSharp {
                                    gatsbyImageData(
                                        height: 160
                                        quality: 100
                                        placeholder: NONE
                                        formats: [AUTO, WEBP, AVIF]
                                    )
                                }
                            }
                            ingress
                            by
                            byImage {
                                childImageSharp {
                                    gatsbyImageData(
                                        placeholder: NONE
                                        formats: [AUTO, WEBP, AVIF]
                                    )
                                }
                            }
                        }
                        fields {
                            slug
                        }
                    }
                }
            }
        }
    `
  )

  return <Layout>
    <Seo title="Leveranser" />
    <div className="bg-gray-100">
      <div className="relative pt-6 pb-8">
        <Header />
      </div>
      <div id="cases" className="relative pb-20 px-4 sm:px-6 lg:pb-28 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <h1 className="text-center text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">Leveranser</h1>

          {/*<section className="section">*/}
          {/*  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu elementum ipsum, in elementum ante. Curabitur*/}
          {/*  vestibulum mauris dapibus, tempus urna eu, aliquet ex. Donec sit amet malesuada ipsum. Nam luctus condimentum*/}
          {/*  turpis non congue. Cras ut mi erat. Sed non urna in arcu malesuada congue. Phasellus vel felis pretium, ultrices*/}
          {/*  nunc eu, convallis mi. Proin efficitur condimentum est sed facilisis. Vestibulum ac pulvinar justo, non facilisis*/}
          {/*  eros. Vivamus efficitur, quam eget facilisis facilisis, lorem elit ornare leo, id tincidunt arcu erat vel est.*/}
          {/*</section>*/}

          <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
            {cases.edges.map(edge => {
              return CaseCard(edge);
            })}
          </div>
        </div>
      </div>
    </div>
    <div className="bg-gray-900 text-gray-200">
      <Footer />
    </div>
  </Layout>
}

export default CasePage
